@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&family=Ubuntu:wght@300;400;500&display=swap');

* {
    padding: 0;
    margin: 0;
    outline: 0;
    text-decoration: none;
}

body {
    background-color: #fff;
    font-family: 'Roboto', sans-serif;
}

.main {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
    min-height: 100vh;
    margin: auto;
}

.form-div {
    display: flex;
    flex-direction: column;
    width: 20%;
}

@media (max-width: 768px) {

    /* For tablet and smaller screens */
    .form-div {
        width: 100%;
        /* On smaller screens, set the width to 100% */
    }
}


.form-div .form {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 3vh 2vw;
    /* border: 1px solid rgba(128, 128, 128, 0.3); */
}

.form-div .form .instaLogo {
    width: 200px;
    margin: 20px 0;
}

.form-div .form .input {
    width: 100%;
    padding: .6rem;
    border-radius: 8px;
    border: 1px solid rgba(128, 128, 128, 0.3);
    margin: 3px;
    height: 40px;
}

.form-div .form .input::placeholder {
    font-size: 12px;
    /* Aap apne required size ke mutabiq isay change kar sakte hain */
    color: grey;
    /* Aap placeholder ka color bhi change kar sakte hain */
}

.login-btn {
    width: 100%;
    padding: .3rem;
    font-weight: 500;
    background-color: #4cb5f9;
    border: none;
    border-radius: 8px;
    margin: 8px 0px;
    color: white;
    font-size: 13px;
}

.forgot-password {
    position: relative;
    right: -100px;
    /* right side se distance */
    /* input ke neeche place karne ke liye */
    font-size: 12px;
    /* font size adjust kar sakte hain */
    color: #4cb5f9;
    /* link ka color */
    text-decoration: none;
    /* underline hatane ke liye */
}

.forgot-password:hover {
    text-decoration: underline;
    /* hover effect dene ke liye */
}

#or {
    margin: 20px 0;
}

#or:before,
#or:after {
    content: '';
    color: rgba(128, 128, 128, 0.7);
    margin: 0 10px;
}

.fb-login {
    font-weight: 700;
    font-size: 80%;
    color: #445a8a;
    margin-bottom: 30px;
    margin-top: 10px;
}

.sign-up {
    text-align: center;
    border: 1px solid rgba(128, 128, 128, 0.3);
    padding: 20px;
    margin: 10px;
}

.get-app {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 15px;
}

.store {
    display: flex;
    align-items: center;
    justify-content: center;

}

#play {
    width: 130px;
}

#microsoft {
    width: 105px;
    border-radius: 8px;
}



.input-error {
    width: 100%;
    padding: .6rem;
    border-radius: 8px;
    border: 1px solid red;
    margin: 3px;
    height: 40px;
}

.error-message {
    color: red;
    /* Red text for the error message */
    font-size: 12px;
    margin-top: 5px;
    /* float: left; */
}