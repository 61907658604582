@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');






.container {
    max-width: 1440px !important;
    margin: 0 auto !important;
    padding: 0 30px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,

figure {
    margin: 0;
    padding: 0;
    list-style: none;
    ;
}

a {
    cursor: pointer !important;
}

/* ------------------------------Fonts------------------------------------ */
.fs_12 {
    font-size: 12px;
}
.fs_14 {
    font-size: 14px;
}

.fs_16 {
    font-size: 16px
}

.fs_18 {
    font-size: 18px;
}

.fs_20 {
    font-size: 20px
}

.fs_22 {
    font-size: 22px;
}

.fs_24 {
    font-size: 24px;
}

.fs_26 {
    font-size: 26px;
}

.fs_36 {
    font-size: 36px;
}

.fs_40 {
    font-size: 40px;
}

.fs_44 {
    font-size: 44px;
}

.fs_48 {
    font-size: 48px;
}

.fs_60 {
    font-size: 60px;
}

.fs_62 {
    font-size: 62px;
}

.fs_64 {
    font-size: 64px;
}

/* ----------------------------Font Weight--------------------------- */
.fw_400 {
    font-weight: 400;
}

.fw_500 {
    font-weight: 500;
}

.fw_600 {
    font-weight: 600;
}

.fw_700 {
    font-weight: 700;
}

.fw_800 {
    font-weight: 800;
}

.fw_900 {
    font-weight: 900;
}

.fw_1000 {
    font-weight: 1000;
}

/* -----------------------------Line Height--------------------------- */
.lh_20 {
    line-height: 20px
}

.lh_24 {
    line-height: 24px
}

.lh_28 {
    line-height: 28px
}

.lh_44 {
    line-height: 44px
}

.lh_38 {
    line-height: 38px
}

.lh_32 {
    line-height: 32px
}

.lh_30 {
    line-height: 30px
}

.lh_36 {
    line-height: 36px
}

.lh_56 {
    line-height: 66px
}

.lh_62 {
    line-height: 62px
}

.lh_72 {
    line-height: 72px
}

.lh_60 {
    line-height: 60px
}

.lh_45 {
    line-height: 45px
}


/* ----------------------------Font Family--------------------------- */
.Dm_family {
    font-family: Dm Sans;
}

.Inter_family {
    font-family: Inter;
}

.text_right {
    text-align: right;
}

.veitname {
    font-family: Be Vietnam Pro;
}


/* ----------------------------Font color---------------------------- */

.main_color {
    color: #FFBC47;
}

.color_red {
    color: #FE5C5C;
}

.bg_red {
    background-color: #FE5C5C;
}

.color_black {
    color: #5f666f;
    transition: box-shadow 0.5s ease;
}

.ittalic {
    font-style: italic;
}

.bg-malta {
    background-color: #FFBC47;
}

.black_2 {
    color: #656A7A;
}

/* ----------------------------Main Padding---------------------------- */
.p_80 {
    padding: 80px 0;
}

.mt_40 {
    padding-top: 40px;
}

.mt_40 {
    margin-top: 40px;
}

.pt_60 {
    padding-top: 60px;
}

.pt_100 {
    padding-top: 100px;
}

/* ----------------------------button Padding---------------------------- */
.whole_button {
    padding: 8px 45px;
    font-weight: 600;
    font-family: Dm Sans;
    line-height: 24px;
    border: 1px solid black;
    border-radius: 0px;
    border-radius: 0px;
    width: fit-content;
    color: white;
    background-color:black;
    transition: box-shadow,color, 0.3s ease;
    text-decoration: none;
    ;
}
.whole_button:hover {
    padding: 8px 45px;
    font-weight: 600;
    font-family: Dm Sans;
    line-height: 24px;
    border: 1px solid black;
    border-radius: 0px;
    border-radius: 0px;
    width: fit-content;
    color: black;
    transition: box-shadow,color, 0.3s ease;
    background-color:transparent;
    ;
}


/* --------------------------------------------------CSS for PAGE---------------------------------------------- */
nav{
    position: fixed;
    top: 0;
    z-index: 999;
    background-color: white;;
    width: 100%;

}
.hero-section{
padding-top: 100px;
}

.main_hero-line{
    background-color:rgba(136, 132, 141, 0.133);
    border-radius:15px;
    padding: 2rem;
    margin-top:20px;
    
}
.sub-hero{
    max-width: 850px;
}
.sub-hero p{
    font-size: 14px;
    color:black;
    font-weight: 400;
    padding-top: 10px;
}
.sub-hero h3{
    font-size: 32px;
    font-family: Plus Jakarta Sans;
    font-weight: 700;
}
.rocket-div{
    padding: 2px 5px;
    background-color:rgba(136, 132, 141, 0.133);
    width: fit-content;
}
.sub-card{
    background-color:#393939;
    color: white;
    border-radius:15px
}
footer{
    background-color:#393939;
}
.footer-bar{
    background-color:black;
}
.checkout-card{
    max-width:180px
}
/* .container12 {
    display: flex;
    max-width: 900px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
  } */

  .left-section {
    flex: 1;
    padding-right: 20px;
    text-align: center;
  }

  .high-demand {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    background-color: #e74c3c;
    color: #fff;
    padding: 5px 10px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .high-demand span {
    font-size: 12px;
  }

  .product-title {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0;
  }

  .right-section {
    flex: 1;
    padding-left: 20px;
    background-color: #f3f3f3;
    text-align: center;
    padding: 20px;
    border-radius: 5px;
  }

  .right-section h3 {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .gender-select {
    margin-bottom: 15px;
    padding: 8px;
    border: 1px solid #28a745;
    border-radius: 5px;
    font-size: 16px;
  }

  .size-options {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: center !important;
    gap: 10px;
  }

  .size-option {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: all 0.3s;
  }

  .size-option.selected {
    background-color: #28a745;
    color: #fff;
    border-color: #28a745;
  }

.nextButton{
    transition: 1s ease-in-out;
}
.before{
    font-size: 300px;
}
.translate-left{
    transform: translateX(-100px);
    margin-top: 50px;
}






.platform-checkbox {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 1s;
  }
  .platform-checkbox input[type="checkbox"] {
    display: none;
  }
  .platform-checkbox.selected {
    background-color: #f0f0f0;
    border:2px solid rgb(1, 70, 6)
  }
  #proceedButton {
    display: none;
    position: fixed;
    bottom: 20%;
    right: 20%;
    transform: translateX(-50%);
    transition: transform 1s ease-in-out;
  }


@media (max-width:521px){
    .auto-on-mobile{
        overflow: auto;
    }
    .checkout-card{
        min-width: 230px;
    }
    .translate-left{
        transform: translateX(0px);
        margin-top: 50px;
    }
    .translation{
        transform: translate(0px)!important;
    }
    .translation img{
        height: 100%;width: auto;
    }
    .before{
        font-size: 100px;
    }
    .container{
        padding:0 10px;
    }

}